import Link from 'next/link'
import { cn } from '@lib/utils'

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  submitButtonLabel: string
}

export default function FormTermsAndConditions({
  className,
  submitButtonLabel,
}: Props) {
  return (
    <p className={cn('mt-6 text-body4', className)}>
      You may unsubscribe from these communications at any time. For more
      information on how to unsubscribe, our privacy practices, and how we are
      committed to protecting and respecting your privacy, please visit the
      Privacy Policy. This site is protected by reCAPTCHA and the Google
      <a href='https://policies.google.com/privacy'> Privacy Policy</a> and
      <a href='https://policies.google.com/terms'> Terms of Service</a> apply.
      By clicking {submitButtonLabel}, you agree to our{' '}
      <Link href={'/privacy'}>Privacy Policy</Link>.
    </p>
  )
}
