// Recommended by event by GA4: https://support.google.com/analytics/answer/9267735
// These events are used across all analytics tools for consistency
export enum StandardEvent {
  GenerateLead = 'generate_lead',
  SelectContent = 'select_content',
  Share = 'share',
  SignUp = 'sign_up',
  QualifyLead = 'qualify_lead',
  DisqualifyLead = 'disqualify_lead',
  WorkingLead = 'working_lead',
}

export enum CampaignEvent {
  LinkedinConversion = 'linkedin_conversion',
}

export enum FormEvent {
  GatedContentDownload = 'gated_content_download',
  Contact = 'contact',
}

export type AnalyticsEvent = StandardEvent | CampaignEvent | FormEvent

// Posthog identity parameters
export interface PosthogPerson {
  firstName: string
  lastName: string
  email: string
  industry?: string
  jobTitle: string
  company?: string
  phoneNumber?: string
  country?: string
  subscribe: boolean
}
